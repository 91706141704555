* {
  margin: 0px;
  padding: 0px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}
@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media only screen and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media only screen and (max-width: 37.5em) {
  html {
    font-size: 43.75%;
  }
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  font-weight: inherit;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

input,
button,
textarea,
select {
  font: inherit;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat'),
    url(/fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat'),
    url(/fonts/Montserrat-Medium.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins'),
    url(/fonts/Poppins-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins'),
    url(/fonts/Poppins-Medium.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto Slab';
  src:
    local('Roboto Slab'),
    url(/fonts/Roboto-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Slab';
  src:
    local('Roboto Slab'),
    url(/fonts/Roboto-Medium.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Sacramento';
  src:
    local('Sacramento'),
    url(/fonts/Sacramento-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Lofty Goals';
  src:
    local('Lofty Goals'),
    url(/fonts/LoftygoalsRegular.otf) format('opentype');
}

.pw-sticky-topbar {
  width: 100%;
  position: fixed;
  z-index: 1241231231231;
}
